import React from 'react'
import { graphql } from 'gatsby'

import CMS from '../../components/CMS'
import SEO from '../../components/SEO'
import Layout from '../../layout/BasicPage'

import './SimplePage.scss'

const SimplePage = ({ data, location }) => {
	const { page } = data

	return (
		<Layout width='wide'>
			<SEO {...SEO.metadata(page)} href={location.href} />
			<Layout.Header
				title={CMS.title(page)}
				subtitle={CMS.subtitle(page)}
				hero={CMS.hero(page)}
			/>
			<Layout.Inset>
				<Layout.RevisionDate {...page} />
				<CMS.Body {...page} />
			</Layout.Inset>
		</Layout>
	)
}

export default SimplePage

export const query = graphql`
	query SimplePage($relativePath: String) {
		page: file(relativePath: { eq: $relativePath }) {
			...SEOFragment
			...CMSFragment
		}
	}
`
